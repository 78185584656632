import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import plotter from "../images/plotter.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="plotting madly wtf" />
    <h1>PLOTTING MADLY</h1>
    <img src={plotter} />

    {/*
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    */}
    {/*<Link to="/page-2/">Go to page 2</Link>*/}
  </Layout>
)

export default IndexPage
